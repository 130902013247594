.vote_intitule {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 1em;
    color: var(--mainColor);
    margin-bottom: 0;
}

.vote {
    width: 300px;
}

.vote_subtitle {
    margin-top: 0;
    color: white;
    opacity: 0.8;
}

.vote_users {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 20px 0;
}

.vote_selectUser {
    padding: 6px 12px;
    margin: 5px 0px;
    margin-right: 10px;
    font-size: 26px;
    background-color: whitesmoke;
    border-radius: 6px;
    font-weight: 500;
}

.vote_waitingScreen {
    font-size: 20px;
    font-weight: 500;
    color: whitesmoke;
    animation: 1.8s alternateOpacity ease-in-out infinite;
}

@keyframes alternateOpacity {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

.vote_sortedUser {
    width: 100%;
    background-color: rgba(0,0,0,0.3);
    padding: 10px 10px;
    box-sizing: border-box;
    border-radius: 10px;
    color: var(--mainColor);
    text-align: center;
    margin: 10px 0;
    font-size: 26px;
}


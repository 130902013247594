.question_elem {
    font-size: 22px;
    padding: 20px 15px;
    height: 150px;
}

.question_choix {
    color: grey;
    font-size: 24px;
    margin: 0;
    font-weight: bold;
}

.question_intitule {
    font-size: 28px;
    margin: 0;
}

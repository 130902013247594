.question {
    padding: 0 30px;
}

.question_intitule {
    color: var(--mainColor);
    font-size: 20px;
    font-weight: 500;
}

.question_intitule span {
    color: white;
}

.question_elem {
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: whitesmoke;
    height: 60px;
    text-align: center;
    font-weight: 500;
}
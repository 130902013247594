

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --mainColor: #F9CC31;
  --secondaryColor: #0D1D36;
}

a {
  text-decoration: none;
}

.base_button {
  background-color: var(--mainColor);
  width: 200px;
  height: 50px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
  transform: scale(1);
  transition: 0.1s;
}

.base_button:active {
  transform: scale(0.85);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ingame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--secondaryColor); 
    min-height: 100vh;
    padding: 20px 16px;
    box-sizing: border-box;
}

.ingame_button {
    margin-top: 15px;
}
.accueil_container {
    min-height: 100vh;
    background-color: var(--secondaryColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.accueil_img {
    width: 90%;
    max-width: 300px;
    margin-bottom: 30px;
}

.accueil_join {
    color: var(--mainColor);
    font-size: 20px;
    width: 80%;
    margin: 0px;
    margin-bottom: 5px;
    text-align: left;
    font-weight: 500;
}

.accueil_input {
    padding: 0px 10px;
    height: 40px;
    width: 80%;
    box-sizing: border-box;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 0px;

}
.lobby {
    min-height: 100vh;
    background-color: var(--secondaryColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.lobby_linkContainer {
    margin-top: 10px;
}

.lobby_linkText {
    margin: 0;
}

.lobby_linkImg {
    width: 26px;
    height: 26px;
    margin-left: 17px;
}

.lobby_button {
    margin-top: 15px;
    padding: 0px 10px;
}

.lobby_join {
    color: var(--mainColor);
    font-size: 20px;
    width: 80%;
    margin: 0px;
    margin-bottom: 5px;
    text-align: left;
    font-weight: 500;
}

.lobby_input {
    padding: 0px 10px;
    height: 40px;
    width: 80%;
    box-sizing: border-box;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 0px;

}

.lobby_players {
    width: 90%;
    max-height: 220px;
    overflow: scroll;
}

.lobby_player {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: rgba(255,255,255,0.3);
    height: 60px;
}

.lobby_player_img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 10px;
    margin-top: 4px;
}

.lobby_player_name {
    color: white;
    font-size: 20px;
    margin-left: 10px;
    flex: 1;
}

.lobby_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.lobby_rule {
    color: whitesmoke;
    width: 90%;
    margin: 0;
    font-weight: 500;
    margin-bottom: 5px;
}

.lobby_rules {
    color: var(--mainColor);
    font-size: 20px;
    width: 90%;
    margin: 0px;
    margin-bottom: 16px;
    text-align: left;
    font-weight: 500;
}

.lobby_player_ready {
    color: white;
    font-size: 15px;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    margin-right: 10px;
    width: 80px;
    font-weight: 600;
}

